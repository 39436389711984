import React from "react";
// import meat from "../../assets/meat.jpeg";
import meet from "../../assets/meet.jpg";
import boss from "../../assets/boss.jpeg";
import ava from "../../assets/emp.jpg";

function AboutUs() {
  return (
    <div className="container mx-auto py-8 px-4">
      <div className="flex flex-col gap-8">
        {/* <div className="text-center h-[200px] bg-cover bg-center bg-about-bg flex justify-center items-center">
          <h1 className="text-[24px] sm:text-[36px] md:text-[48px] font-bold text-white">
            About Us
          </h1>
        </div> */}

        <div className="bg-white rounded-lg shadow-md p-4 w-full md:w-[800px] lg:w-[959px] flex flex-col mx-auto">
          <h2 className="text-[24px] sm:text-[36px] md:text-[48px] font-bold text-red-600 text-center mb-4">
            Who We Are?
          </h2>
          <img
            src={meet}
            alt="Team meeting"
            className="rounded-md h-[200px] md:h-[370px] object-cover"
          />
          <p className="text-[16px] sm:text-[20px] md:text-[24px] text-gray-600 mt-4">
            We pride ourselves on delivering the highest quality, farm-fresh
            meat directly to your doorstep. Every cut is fresh, responsibly
            sourced, and meticulously treated to the greatest standards thanks
            to our dedication to perfection. Enjoy the convenience and superior
            taste of our dependable, quick, and clean delivery service.
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-8">
          <h2 className="text-[24px] sm:text-[36px] md:text-[48px] font-bold text-red-600 text-center mb-4">
            Our Inspiration
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div>
              <h3 className="text-[20px] sm:text-[28px] md:text-[32px] font-bold text-gray-800 mb-2">
                Mr. Aakil Rasool
              </h3>
              <p className="text-gray-600 text-[16px] sm:text-[18px]">
                Founder & CEO
              </p>
              <p className="text-gray-600 text-[16px] sm:text-[18px] md:text-[24px] mt-4 text-wrap max-w-full md:max-w-[600px]">
                Enjoy the convenience and superior taste of our dependable,
                quick, and clean delivery service.
              </p>
            </div>
            <img
              src={boss}
              alt="Er. Basit"
              className="rounded-md w-40 h-40 md:w-60 md:h-60 object-cover mt-4 md:mt-0"
            />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-[24px] sm:text-[36px] md:text-[48px] font-bold text-red-600 text-center mb-4">
            Meet Our Team
          </h2>
          <p className="text-gray-600 mb-4 text-center text-[16px] sm:text-[20px] md:text-[24px]">
            We are a group of enthusiastic youth who{" "}
            <span className="font-bold text-blue-500">value</span> your trust
            and listen to what you have to say.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
            <div className="bg-white rounded-lg shadow-md p-6">
              <img
                src={ava}
                alt="Basit"
                className="rounded-md w-full h-32 sm:h-48 object-cover"
              />
              <h3 className="text-lg font-bold text-gray-800 mt-4">Basit</h3>
              <p className="text-gray-600">UI/UX Designer</p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <img
                src={ava}
                alt="Zahid"
                className="rounded-md w-full h-32 sm:h-48 object-cover"
              />
              <h3 className="text-lg font-bold text-gray-800 mt-4">Zahid</h3>
              <p className="text-gray-600">Backend Developer</p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <img
                src={ava}
                alt="Jasia"
                className="rounded-md w-full h-32 sm:h-48 object-cover"
              />
              <h3 className="text-lg font-bold text-gray-800 mt-4">Jasia</h3>
              <p className="text-gray-600">Frontend Developer</p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <img
                src={ava}
                alt="Fazil"
                className="rounded-md w-full h-32 sm:h-48 object-cover"
              />
              <h3 className="text-lg font-bold text-gray-800 mt-4">Fazil</h3>
              <p className="text-gray-600">Backend Developer</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
