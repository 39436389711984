import ChooseUs from "../Components/Main/ChooseUs";
import Hero from "../Components/Main/Hero";
import QualitySection from "../Components/Main/QualitySection";
import Reviews from "../Components/Main/Reviews";
import Works from "../Components/Main/Works";

const LandingPage = () => {
  return (
    <>
      <Hero />
      <QualitySection />
      <Works />
      <ChooseUs />
      <Reviews />
    </>
  );
};

export default LandingPage;
