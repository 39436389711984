import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faDrumstickBite } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URI } from "../../URI/baseUri";
import { useDispatch } from "react-redux";
import { userCartActions } from "../../Store/cartSlice";

const Products = () => {
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState("");
  const [sortOption, setSortOption] = useState("lowToHigh");
  const [addedProductId, setAddedProductId] = useState(null);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    const fetchCategories = async () => {
      axios({
        method: "GET",
        url: `${BASE_URI}/api/v1/categories/get-category/${id}`,
      }).then(
        (res) => {
          setProducts(res.data.data.items);
          setCategory(res.data.data.categoryName);
          setLoading(false);
        },
        (err) => {
          console.log(err);
          setLoading(false);
        }
      );
    };
    fetchCategories();
  }, [id]);

  const handleAddToCart = (productId) => {
    axios({
      method: "PATCH",
      url: `${BASE_URI}/api/v1/cart/add/${productId}`,
    }).then(
      (res) => {
        dispatch(userCartActions.setCart(res.data.data[0]));
        setAddedProductId(productId);
        setTimeout(() => setAddedProductId(null), 3000);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const sortedProducts = [...products].sort((a, b) => {
    if (sortOption === "lowToHigh") {
      return a.price - b.price;
    } else {
      return b.price - a.price;
    }
  });

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="flex justify-center items-center min-h-screen">
            <div className="loader animate-spin rounded-full h-32 w-32 border-t-8 border-b-8 border-red-500"></div>
          </div>
        </div>
      ) : (
        <div className="container mx-auto px-4 md:px-8 lg:px-[100px] py-8 md:py-12 lg:py-[20px] relative">
          <div className="flex justify-between items-center mb-8">
            <div className="text-2xl md:text-3xl lg:text-[30px] leading-[36px] font-[900]">
              {category}
            </div>
            <div className="flex items-center">
              <p className="text-[16px] leading-[36px] font-[500] mr-2">
                Sort by:{" "}
              </p>
              <select
                className="text-[16px] leading-[36px] font-[400]"
                onChange={handleSortChange}
                value={sortOption}
              >
                <option value="lowToHigh">Low to high</option>
                <option value="highToLow">High to low</option>
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            {sortedProducts.map((el) => (
              <div
                className="flex flex-col w-full h-auto border rounded-[8px] hover:shadow-bl relative p-4 hover:scale-105 transition duration-500"
                key={el._id}
              >
                <img
                  alt="img"
                  src={el.itemImage}
                  className="w-full h-[210px] rounded-[6px] mb-4 object-cover mt-auto"
                ></img>
                <div className="text-lg md:text-xl font-semibold mb-2 mt-auto">
                  {el.itemName}
                </div>
                <div className="text-sm md:text-base font-normal mb-4 mt-auto">
                  {el.description}
                </div>
                <div className="text-sm md:text-base font-semibold mb-4 mt-auto">
                  &#8377; {el.price} / {el.quantityInGrams} gm
                </div>
                <button
                  onClick={() => handleAddToCart(el._id)}
                  className={`flex mt-auto items-center justify-center w-full h-10 ${
                    addedProductId === el._id ? "bg-green-600" : "bg-red-600"
                  } text-white text-sm md:text-base font-medium rounded-md ${
                    addedProductId === el._id
                      ? "hover:bg-green-500"
                      : "hover:bg-red-500"
                  } transition duration-300`}
                >
                  {addedProductId === el._id
                    ? "Added successfully 😊"
                    : "Add to cart"}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-md shadow-md w-[90%] md:w-[70%] lg:w-[393px] h-auto md:h-[583px] top-[7%] relative">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg md:text-xl font-semibold">
                Customize as you like!
              </h2>
              <button onClick={closePopup}>
                <FontAwesomeIcon icon={faClose} className="w-6 h-6" />
              </button>
            </div>
            <hr />
            <div className="mt-4">
              <p className="text-base font-semibold">Size</p>
              <p className="text-sm mt-2 font-light">Select any 1</p>
            </div>
            <div className="mt-6 space-y-4">
              {["250g", "500g", "1kg", "3kg", "5kg"].map((size) => (
                <div
                  className="flex items-center justify-between border p-3 rounded-md"
                  key={size}
                >
                  <label className="flex items-center text-base font-medium">
                    <FontAwesomeIcon
                      icon={faDrumstickBite}
                      className="text-red-600 mr-2"
                    />
                    {size}
                  </label>
                  <input type="radio" name="size" />
                </div>
              ))}
            </div>
            <div className="flex justify-between items-center mt-8 border-t pt-4">
              <div className="flex items-center border px-4 py-2 rounded-md">
                <button
                  className="text-red-600 text-2xl font-semibold"
                  onClick={decrementQuantity}
                >
                  -
                </button>
                <span className="mx-4 text-red-600 text-xl font-semibold">
                  {quantity}
                </span>
                <button
                  className="text-red-600 text-2xl font-semibold"
                  onClick={incrementQuantity}
                >
                  +
                </button>
              </div>
              <button className="flex items-center justify-center w-40 h-12 bg-red-600 text-white text-lg font-medium rounded-md">
                Add | Rs. 240
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Products;
