import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URI } from "../../URI/baseUri";
import { useNavigate } from "react-router-dom";

const Address = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [purchaseAdd, setPurchaseAdd] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleEditClick = (address) => {
    setCurrentAddress(address);
    setIsEditing(true);
  };

  const handleAddNewClick = () => {
    setCurrentAddress({
      houseNo: "",
      fullName: "",
      district: "",
      state: "",
      landmark: "",
      pin: "",
      phone: "",
    });
    setIsEditing(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setCurrentAddress({
      ...currentAddress,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: `${BASE_URI}/api/v1/address/add`,
      data: currentAddress,
    }).then(
      (res) => {
        setAddress((prevState) => [...prevState, res.data.data]);
      },
      (err) => {
        console.log(err);
      }
    );
    setIsEditing(false);
    setCurrentAddress(null);
  };

  const setPurchaseAddress = (e) => {
    setPurchaseAdd(e.target.value);
  };

  const handlePurchase = () => {
    axios({
      method: "POST",
      url: `${BASE_URI}/api/v1/payments/create`,
      data: { addressId: purchaseAdd },
    }).then(
      (res) => {
        const order = res.data.data;
        const selectedAddress = address.find((el) => el._id === purchaseAdd);
        navigate("/pay", {
          state: { order, purchaseAdd, selectedAddress },
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${BASE_URI}/api/v1/address/get`,
    }).then(
      (res) => {
        setAddress(res.data.data);
        setIsLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  return (
    <>
      <div className="container mx-auto px-4 py-8 md:py-12">
        <div className="text-2xl font-bold mb-6 text-center">
          Shipping Address
        </div>

        {isLoading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 bg-gray-100 p-8 rounded-xl shadow-bl">
            {address.map((el) => (
              <div key={el._id} className="bg-white p-4 border-2 rounded-md">
                <div className="text-lg font-semibold">{el.fullName}</div>
                <div>
                  {el.houseNo}, {el.landmark},{el.district}, {el.state} -{" "}
                  {el.pin}
                </div>
                <div>{el.phone}</div>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="selectedAddress"
                    id={`address-${el._id}`}
                    value={el._id}
                    onChange={setPurchaseAddress}
                  />
                  <label htmlFor={`address-${el._id}`} className="ml-2 text-xl">
                    Select Address
                  </label>
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    className="px-4 py-2 text-sm font-semibold text-white bg-red-600 rounded-md hover:bg-red-700 tracking-wider"
                    onClick={() => handleEditClick(el)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex justify-between mt-6">
          <button
            className="px-4 py-2 text-sm font-semibold text-white bg-red-600 rounded-md hover:bg-red-700 tracking-wider"
            onClick={handleAddNewClick}
          >
            Add New Address
          </button>

          <button
            className="px-4 py-2 text-sm font-semibold text-white bg-gray-700 rounded-md hover:bg-gray-800 tracking-widest leading-8"
            onClick={handlePurchase}
          >
            Continue to Checkout
          </button>
        </div>

        {isEditing && (
          <div className="fixed inset-0 top-[60px] bg-gray-500 bg-opacity-30 flex items-center justify-center">
            <div className="bg-white p-4 rounded-md shadow-lg">
              <h2 className="text-lg font-semibold mb-4">Edit Address</h2>
              <form onSubmit={handleFormSubmit}>
                <input
                  type="text"
                  name="fullName"
                  value={currentAddress.fullName}
                  onChange={handleFormChange}
                  placeholder="Full Name"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                />
                <input
                  type="text"
                  name="houseNo"
                  value={currentAddress.houseNo}
                  onChange={handleFormChange}
                  placeholder="House No"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                />
                <input
                  type="text"
                  name="landmark"
                  value={currentAddress.landmark}
                  onChange={handleFormChange}
                  placeholder="Landmark"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                />
                <input
                  type="text"
                  name="pin"
                  value={currentAddress.pin}
                  onChange={handleFormChange}
                  placeholder="Pincode"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                />
                <input
                  type="text"
                  name="phone"
                  value={currentAddress.phone}
                  onChange={handleFormChange}
                  placeholder="Phone Number"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                />
                <input
                  type="text"
                  name="district"
                  value={currentAddress.district}
                  onChange={handleFormChange}
                  placeholder="District"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                />
                <input
                  type="text"
                  name="state"
                  value={currentAddress.state}
                  onChange={handleFormChange}
                  placeholder="State"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                />

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm font-semibold text-white bg-red-600 rounded-md hover:bg-red-700 mr-2"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-semibold text-white bg-gray-700 rounded-md hover:bg-gray-800"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Address;
