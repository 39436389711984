import React from "react";
import { NavLink } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen ">
      <div className="bg-white p-8 rounded-xl shadow-2xl text-center max-w-lg">
        <div className="text-6xl mb-4">😵</div>
        <h1 className="text-4xl font-bold text-red-600 mb-4">Oops!</h1>
        <p className="text-gray-800 text-lg mb-6">
          Something went wrong. Please try again later.
        </p>
        <NavLink
          to={"/"}
          className="px-6 py-3 bg-red-600 text-white font-semibold rounded-full hover:bg-blue-700 transition duration-300 transform hover:scale-105"
        >
          Go Home
        </NavLink>
      </div>
    </div>
  );
};

export default ErrorPage;
