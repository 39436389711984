import img1 from "../../assets/quality1.png";
import img2 from "../../assets/quality2.png";
import img3 from "../../assets/quality3.png";
import img4 from "../../assets/quality3.png";
import img5 from "../../assets/quality2.png";
import img6 from "../../assets/quality1.png";
import { NavLink } from "react-router-dom";

const images = [img1, img2, img3, img4, img5, img6];

function ChooseUs() {
  return (
    <section className="w-full py-12 bg-gray-100">
      <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8 lg:mb-0 lg:w-1/2">
          {images.map((imgSrc, index) => (
            <img
              key={index}
              src={imgSrc}
              alt={`img${index + 1}`}
              className="rounded-lg h-40 w-40 md:h-48 md:w-48 object-cover shadow-bl transform transition-transform duration-300 hover:scale-95"
            />
          ))}
        </div>
        <div className="lg:w-1/2 lg:pl-12">
          <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
            Why Choose <br /> PUJJ - KAAK?
          </h2>
          <p className="text-lg md:text-xl text-gray-600 mb-6">
            We prioritize quality, convenience, and sustainability in every
            delivery.
          </p>
          <NavLink
            to="/categories"
            className="px-6 py-3 rounded-full bg-red-600 text-white text-lg md:text-xl"
          >
            Buy Now!
          </NavLink>
        </div>
      </div>
    </section>
  );
}

export default ChooseUs;
