import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URI } from "../../URI/baseUri";
import { userCartActions } from "../../Store/cartSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { useState } from "react";

const Cart = () => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);

  const itemDetails = cart?.itemDetails || [];
  const [errorMessage, setErrorMessage] = useState("");

  const totalPrice = itemDetails.reduce((total, item) => {
    return total + item.price * item.units;
  }, 0);

  async function incrementQuantity(id) {
    try {
      const res = await axios.patch(`${BASE_URI}/api/v1/cart/add/${id}`);
      dispatch(userCartActions.setCart(res.data.data[0]));
    } catch (err) {
      setErrorMessage("Failed to increase item quantity. Please try again.");
    }
  }

  async function decrementQuantity(id) {
    try {
      const res = await axios.patch(`${BASE_URI}/api/v1/cart/remove/${id}`);
      dispatch(userCartActions.setCart(res.data.data[0]));
    } catch (err) {
      setErrorMessage("Failed to decrease item quantity. Please try again.");
    }
  }

  async function deleteItemHandler(id) {
    try {
      const res = await axios.patch(`${BASE_URI}/api/v1/cart/delete/${id}`);
      dispatch(userCartActions.setCart(res.data.data[0]));
    } catch (err) {
      setErrorMessage("Failed to remove item from cart. Please try again.");
    }
  }

  return (
    <>
      <div className="container mx-auto px-4 md:px-8 py-8 md:py-12 ">
        <div className="flex justify-between mb-8">
          <div className="text-2xl md:text-3xl lg:text-[30px] leading-[36px] font-[900]">
            Shopping Cart
          </div>
        </div>

        {itemDetails.length === 0 ? (
          <div className="flex flex-col items-center">
            <p className="text-xl text-gray-600 mb-4">Your cart is empty 😥</p>
            <p className="text-xl text-gray-600 mb-4">
              Click below to select from various fresh meat categories!
            </p>
            <NavLink
              to="/categories"
              className="px-4 py-2 rounded bg-red-600 text-white hover:scale-105 transition-all duration-300"
            >
              Go to Products
            </NavLink>
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-6 place-items-center">
              {itemDetails.map((el) => (
                <div
                  className="flex flex-col md:flex-row w-full h-auto md:h-[300px] border rounded-[8px] shadow-bl p-4 md:place-items-center relative"
                  key={el.id}
                >
                  <div className="w-full md:w-[290px] h-[230px] mb-4 md:mb-0 md:mr-4">
                    <img
                      alt="img"
                      src={el.itemImage}
                      className="w-full h-full rounded-[6px] object-cover"
                    ></img>
                  </div>

                  <div className="flex flex-col w-full">
                    <div className="flex justify-between items-center mb-4">
                      <div className="text-lg md:text-xl font-semibold">
                        {el.itemName}
                      </div>
                      <div className="text-red-600 text-md md:text-lg">
                        &#8377; {el.price} / {el.quantityInGrams} gm
                      </div>
                    </div>
                    <span className="text-sm md:text-base mb-4 break-words">
                      {el.description}
                    </span>
                    <div className="flex justify-between items-center mb-4">
                      <div className="flex justify-center border rounded shadow-bl p-1 items-center">
                        <button
                          className="px-4 text-red-600 "
                          onClick={() => decrementQuantity(el.id)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <span className="px-4 text-red-600 font-semibold text-xl">
                          {el.units}
                        </span>
                        <button
                          className="px-4 text-red-600 "
                          onClick={() => incrementQuantity(el.id)}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>
                      <button
                        onClick={() => deleteItemHandler(el.id)}
                        className="ml-4 px-4 py-2 rounded bg-red-600 text-white hover:scale-105 transition-all duration-300"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col items-end mt-8">
              <div className="text-lg md:text-xl font-semibold mb-4">
                Total Price: &#8377; {totalPrice}
              </div>
              <div className="flex space-x-4">
                <NavLink
                  to={"/address"}
                  className="w-[128px] h-[44px] rounded bg-gray-800 text-white flex items-center justify-center hover:scale-105 transition-all duration-300 shadow-md shadow-black/50"
                >
                  Checkout
                </NavLink>
                <NavLink
                  to={"/categories"}
                  className="w-[187px] h-[44px] rounded bg-red-600 text-white flex items-center justify-center hover:scale-105 transition-all duration-300 shadow-md shadow-black/50"
                >
                  Continue shopping
                </NavLink>
              </div>
            </div>
          </>
        )}

        {errorMessage && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
              <h2 className="text-xl font-bold mb-4">Error</h2>
              <p className="mb-4">{errorMessage}</p>
              <button
                className="w-full h-10 bg-red-600 text-white font-medium rounded-lg"
                onClick={() => setErrorMessage("")}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Cart;
