import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { BASE_URI } from "../../URI/baseUri";
import { userCartActions } from "../../Store/cartSlice";

axios.defaults.withCredentials = true;
const Cart = () => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);

  useEffect(() => {
    console.log("use effect");
    const getMyCart = async () => {
      axios({
        method: "GET",
        url: `${BASE_URI}/api/v1/cart/get`,
      }).then(
        (res) => {
          console.log(res.data.data);
          dispatch(userCartActions.setCart(res.data.data));
        },
        (err) => {
          console.log(err);
        }
      );
    };

    getMyCart();
  }, [dispatch]);

  return (
    <>
      <NavLink to="/myCart" className="flex items-center hover:text-black">
        <FontAwesomeIcon
          icon={faShoppingCart}
          className="w-4 h-4 mr-2 text-black"
        />
        {cart ? cart.totalItems : 0} Cart
      </NavLink>
    </>
  );
};

export default Cart;
