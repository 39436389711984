import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URI } from "../../URI/baseUri";
import { NavLink } from "react-router-dom";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(
          `${BASE_URI}/api/v1/categories/get-categories`
        );
        setCategories(res.data.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  return (
    <div className="container mx-auto relative p-4">
      <div className="h-[60px] mt-2 mb-2 ml-12">
        <h3 className="font-[700] text-[24px] sm:text-[28px] md:text-[32px] lg:text-[36px]">
          Categories
        </h3>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="flex justify-center items-center min-h-screen">
            <div className="loader animate-spin rounded-full h-32 w-32 border-t-8 border-b-8 border-red-500"></div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 place-items-center">
          {categories.map((category) => (
            <div
              key={category.categoryName}
              className="w-full flex justify-center"
            >
              <NavLink to={`/products/${category._id}`}>
                <div className="flex flex-col items-center text-center gap-3 hover:scale-105 transition duration-500 hover:bg-[#6d6d6d] hover:text-white rounded-xl p-6 hover:shadow-bl">
                  <img
                    src={category.categoryImage}
                    alt="img"
                    className="rounded-full w-[80px] h-[80px] sm:w-[100px] sm:h-[100px] md:w-[120px] md:h-[120px] lg:w-[140px] lg:h-[140px] shadow-bl "
                  />
                  <div className="w-[100px] sm:w-[130px] md:w-[160px] lg:w-[185px] text-[14px] sm:text-[16px] md:text-[18px] tracking-wider font-[600] leading-snug">
                    {category.categoryName}
                  </div>
                </div>
              </NavLink>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Categories;
