import React, { useState } from "react";

const OrderCard = ({ orders }) => {
  const [expandedOrderId, setExpandedOrderId] = useState(null);

  const toggleOrderDetails = (orderId) => {
    setExpandedOrderId(orderId === expandedOrderId ? null : orderId);
  };

  return (
    <div className="w-[800px] mt-auto col-span-2 h-[400px] overflow-y-scroll hide-scrollbar">
      {orders.map((order) => (
        <div key={order.id} className="mb-4 p-4 border rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <div>
              <div className="text-lg font-bold">Order Date: {order.date}</div>
              <div className="text-sm text-gray-600">
                Status: {order.status}
              </div>

              <div className="text-sm text-gray-600">
                Total: ₹{order.amount}
              </div>
            </div>
            <button
              onClick={() => toggleOrderDetails(order.id)}
              className="text-red-600 hover:underline"
            >
              {expandedOrderId === order.id ? "Hide Details" : "Show Details"}
            </button>
          </div>
          {expandedOrderId === order.id && (
            <div className="mt-4">
              <h3 className="font-semibold">Order Items:</h3>
              <ul className="list-disc ml-5">
                {order.items.map((item, index) => (
                  <li key={index}>
                    {item.itemName} - {item.quantity} x ₹{item.price}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default OrderCard;
