import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { userSliceAction } from "../../Store/userSlice";
import { useNavigate } from "react-router-dom";
import { BASE_URI } from "../../URI/baseUri";
import logo from "../../assets/Logo.svg";
import axios from "axios";
import {
  faHome,
  faInfoCircle,
  faList,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Cart from "../Cart/Cart";
import { userCartActions } from "../../Store/cartSlice";

function Navbar() {
  const { user } = useSelector((state) => state.user);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    axios({
      method: "POST",
      url: `${BASE_URI}/api/v1/users/logout`,
    }).then(
      (res) => {
        dispatch(userSliceAction.setUser(null));
        dispatch(userCartActions.setCart(null));
        setDropdownOpen(false);
        setMenuOpen(false);
        navigate("/");
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const navLinks = [
    { to: "/", label: "Home", icon: faHome },
    { to: "/categories", label: "Categories", icon: faList },
    { to: "/about", label: "About", icon: faInfoCircle },
    { to: "/contact", label: "Contact", icon: faPhone },
  ];

  useEffect(() => {
    setDropdownOpen(false);
  }, [user]);

  return (
    <nav className="sticky top-0 z-[1000] px-4 md:px-8 flex justify-between items-center h-[70px] bg-gray-100 shadow-bl">
      <div className="flex items-center gap-4">
        <NavLink to="/">
          <img src={logo} alt="logo" className="h-12 w-auto" />
        </NavLink>
      </div>
      <div className="hidden md:flex gap-6 font-semibold text-gray-600 text-lg">
        {navLinks.map((link) => (
          <NavLink
            key={link.to}
            to={link.to}
            className="hover:text-[#dc2626] transition-all duration-500 hover:underline underline-offset-8"
          >
            <FontAwesomeIcon icon={link.icon} className="w-4 h-4 mr-2" />
            {link.label}
          </NavLink>
        ))}
      </div>
      <div className="hidden md:flex gap-4 items-center font-semibold text-gray-600 text-lg relative">
        <div
          className="flex items-center hover:text-black cursor-pointer"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <FontAwesomeIcon icon={faUser} className="w-4 h-4 mr-2 text-black" />
          {user ? (
            <span className="text-xs">{user.fullName}</span>
          ) : (
            <NavLink to="/login" onClick={() => setDropdownOpen(false)}>
              Login
            </NavLink>
          )}
        </div>
        {dropdownOpen && user && (
          <div className="absolute top-full right-0 mt-2 w-48 bg-white shadow-lg rounded-lg py-2 z-20">
            <NavLink
              to="/profile"
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              onClick={() => setDropdownOpen(false)}
            >
              Profile
            </NavLink>
            <NavLink
              to="/contact"
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              onClick={() => setDropdownOpen(false)}
            >
              Call us
            </NavLink>
            <button
              className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )}
        <div onClick={() => setMenuOpen(false)}>
          <Cart />
        </div>
      </div>
      <button className="md:hidden" onClick={() => setMenuOpen(!menuOpen)}>
        <svg
          className="w-6 h-6 text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          ></path>
        </svg>
      </button>
      {menuOpen && (
        <div className="absolute top-16 left-0 w-full bg-gray-100 shadow-md md:hidden z-20">
          <div className="flex flex-col items-center gap-4 py-4 font-semibold text-gray-600 text-lg">
            {navLinks.map((link) => (
              <NavLink
                key={link.to}
                to={link.to}
                className="hover:text-[#dc2626] hover:underline underline-offset-8"
                onClick={() => {
                  setMenuOpen(false);
                  setDropdownOpen(false);
                }}
              >
                {link.label}
              </NavLink>
            ))}
            <div
              className="flex items-center hover:text-black cursor-pointer"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <FontAwesomeIcon
                icon={faUser}
                className="w-4 h-4 mr-2 text-black"
              />
              {user ? (
                user.fullName
              ) : (
                <NavLink
                  to="/login"
                  onClick={() => {
                    setMenuOpen(false);
                    setDropdownOpen(false);
                  }}
                >
                  Login
                </NavLink>
              )}
            </div>
            {dropdownOpen && user && (
              <div className="w-full bg-white shadow-lg rounded-lg py-2">
                <NavLink
                  to="/profile"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  onClick={() => {
                    setMenuOpen(false);
                    setDropdownOpen(false);
                  }}
                >
                  Profile
                </NavLink>
                <NavLink
                  to="/contact"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  onClick={() => {
                    setMenuOpen(false);
                    setDropdownOpen(false);
                  }}
                >
                  Call us
                </NavLink>
                <button
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                  onClick={() => {
                    handleLogout();
                    setMenuOpen(false);
                  }}
                >
                  Logout
                </button>
              </div>
            )}
            <div onClick={() => setMenuOpen(false)}>
              <Cart />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
