import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserEdit,
  faHistory,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";

function SideBar() {
  return (
    <div className="container mx-auto p-4 mt-6">
      <h1 className="text-2xl font-bold mb-4 ml-[10px]">My Profile</h1>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col text-center mb-4 md:mr-12">
          <NavLink
            to={"/profile"}
            className="w-full md:w-[300px] rounded-t-xl h-[80px] mb-1 bg-[#D6D6D6] flex justify-center items-center"
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={faUserEdit} className="mr-2" />
              Edit Profile
            </div>
          </NavLink>
          <NavLink
            to={"orders"}
            className="w-full md:w-[300px] h-[80px] bg-[#D6D6D6] mb-1 flex justify-center items-center"
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={faHistory} className="mr-2" />
              Order History
            </div>
          </NavLink>
          <NavLink
            to={"adresses"}
            className="w-full md:w-[300px] h-[80px] rounded-b-xl bg-[#D6D6D6] flex justify-center items-center"
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={faAddressBook} className="mr-2" />
              Saved Addresses
            </div>
          </NavLink>
        </div>
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default SideBar;
