import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

const Redirect = () => {
  const { cart } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const items = cart?.itemDetails || [];
  const { order, purchaseAdd, selectedAddress } = useLocation().state;

  const options = {
    key: "rzp_test_ytAnu6Qngr0srq",
    amount: "50000",
    currency: "INR",
    name: "Acme Corp",
    description: "Test Transaction",
    image:
      "https://rb-screenshots-actwin.s3.ap-south-1.amazonaws.com/jasia.jpeg",
    order_id: order.id,
    callback_url: `http://localhost:4003/api/v1/payments/validate/?addId=${purchaseAdd}&userId=${user._id}/`,
    prefill: {
      name: "Basit Zahid Jasiya",
      email: "basit.zahid.jasiya@example.com",
      contact: "9000090000",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  return (
    <div className="container mx-auto p-4 md:p-8 lg:p-12">
      <div className="flex flex-col border rounded-lg p-6 shadow-bl">
        <h3 className="text-gray-700 text-2xl text-center mb-6">
          ORDER SUMMARY
        </h3>
        <div className="bg-white px-6 py-4 flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-1/2 lg:w-1/3 mb-6 md:mb-0">
            <table className="w-full">
              <thead className="bg-gray-100">
                <tr>
                  <th className="text-gray-700 text-sm py-3 px-4">Item</th>
                  <th className="text-gray-700 text-sm py-3 px-4">Quantity</th>
                  <th className="text-gray-700 text-sm py-3 px-4">Price</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index} className="border-b border-gray-200">
                    <td className="py-3 px-4 text-center text-sm">
                      {item.itemName}
                    </td>
                    <td className="py-3 px-4 text-center text-sm">
                      {item.units}
                    </td>
                    <td className="py-3 px-4 text-center text-sm">
                      &#8377; {item.price}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="w-full md:w-1/2  flex flex-col gap-2">
            <div className="border rounded-lg p-4">
              <h3 className="text-gray-700 text-lg text-center font-semibold mb-2">
                DELIVERY ADDRESS
              </h3>
              <hr />
              <div className="text-sm py-3">
                <div>
                  {selectedAddress?.fullName}, {selectedAddress?.houseNo},{" "}
                  {selectedAddress?.landmark}, {selectedAddress?.district},{" "}
                  {selectedAddress?.state} - {selectedAddress?.pin}
                </div>
                <div>{selectedAddress?.phone}</div>
              </div>
            </div>
            <div className="mt-4 flex flex-col gap-1">
              <div className="flex justify-between">
                <p className="text-gray-700 text-sm">Items:</p>
                <span>{items.units}</span>
              </div>
              <div className="flex justify-between">
                <p className="text-gray-700 text-sm">Delivery Charges:</p>
                <span>₹50</span>
              </div>
              <div className="flex justify-between">
                <p className="text-gray-700 text-sm">Total:</p>
                <span>₹{cart.totalAmount}</span>
              </div>
              <div className="flex justify-between">
                <p className="text-gray-700 text-sm">Discount:</p>
                <span>₹0</span>
              </div>
              <hr />
              <div className="flex justify-between text-red-600">
                <p className="text-gray-800 text-lg ">Order Total:</p>
                <span className="font-semibold">₹{cart.totalAmount}</span>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <form
        method="POST"
        action="https://api.razorpay.com/v1/checkout/embedded"
      >
        <input type="hidden" name="key_id" value="rzp_test_ytAnu6Qngr0srq" />
        <input type="hidden" name="amount" value={options.amount * 1000} />
        <input type="hidden" name="order_id" value={order.id} />
        <input type="hidden" name="name" value="PUJ KAAK" />
        <input
          type="hidden"
          name="description"
          value="FRESH MEAT DELIVERED TO YOU!"
        />
        <input
          type="hidden"
          name="image"
          value="https://rb-screenshots-actwin.s3.ap-south-1.amazonaws.com/jasia.jpeg"
        />
        <input type="hidden" name="prefill[name]" value="BASIT ZAHID JASIA" />
        <input type="hidden" name="prefill[contact]" value="9123456780" />
        <input
          type="hidden"
          name="prefill[email]"
          value="basitzahidjasia@example.com"
        />
        <input
          type="hidden"
          name="notes[shipping address]"
          value="L-16, The Business Centre, 61 Wellfield Road, New Delhi - 110001"
        />
        <input
          type="hidden"
          name="callback_url"
          value={`http://localhost:4003/api/v1/payments/validate/${purchaseAdd}/${user._id}/`}
        />
        <input
          type="hidden"
          name="cancel_url"
          value="https://example.com/payment-cancel"
        />
        <div className="flex flex-col md:flex-row justify-center mt-8 gap-4">
          <button
            type="submit"
            className="bg-gray-600 hover:bg-gray-700 transition-all duration-300 text-white font-bold py-3 px-5 rounded w-full md:w-auto"
          >
            PAY ONLINE (UPI, Net Bank)
          </button>
          <NavLink
            to="/"
            className="bg-red-600 hover:bg-red-700 transition-all duration-300 text-white font-bold py-3 px-5 rounded text-center w-full md:w-auto"
          >
            CASH ON DELIVERY
          </NavLink>
        </div>
      </form>
    </div>
  );
};

export default Redirect;
