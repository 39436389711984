import React, { useEffect, useState } from "react";
import OrderCard from "./OrderCard";
import axios from "axios";
import { BASE_URI } from "../../../URI/baseUri";

function MyOrders() {
  const [myOrders, setMyOrders] = useState([]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${BASE_URI}/api/v1/orders/customer-all`,
    }).then(
      ({ data }) => {
        let orders = data.data;
        for (let i = 0; i < orders.length; i++) {
          setMyOrders((prev) => [
            ...prev,
            {
              id: orders[i]._id,
              items: orders[i].itemDetails,
              status: orders[i].status,
              amount: orders[i].amountPaid,
              paymentMode: orders[i].paymentMode,
            },
          ]);
        }
      },
      (err) => {}
    );
  }, []);

  // console.log(myOrders);

  return <OrderCard orders={myOrders} />;
}

export default MyOrders;
