import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./App.css";

import Login from "./Pages/Authentication/Login";
import Signup from "./Pages/Authentication/Signup";
import Navbar from "./Components/Header/Navbar";
import LandingPage from "./Pages/LandingPage";
import Footer from "./Components/Footer/Footer";

import Products from "./Pages/Products/Products";

import { useEffect } from "react";
import axios from "axios";

import { BASE_URI } from "./URI/baseUri";
import { userSliceAction } from "./Store/userSlice";
import Categories from "./Pages/Products/Categories";
import Cart from "./Pages/Cart/Cart";
import ErrorPage from "./Pages/Error/ErrorPage";
import Address from "./Pages/Checkout/Address";
import Redirect from "./Pages/Checkout/Redirect";

// import Payment from "./Pages/Payment";
import AboutUs from "./Pages/Contact/AboutUs";
import Profile from "./Pages/User/SideBar/SideBar";
import AboutMe from "./Pages/User/Profile/AboutMe";
import MyOrders from "./Pages/User/Orders/MyOrders";
import MyAddresses from "./Pages/User/Adresses/MyAddresses";
import Orders from "./Pages/Admin/Orders";

axios.defaults.withCredentials = true;

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Navbar />
        <Outlet />
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signUp",
        element: <Signup />,
      },
      {
        path: "/products/:id",
        element: <Products />,
      },
      {
        path: "/categories",
        element: <Categories />,
      },
      {
        path: "/myCart",
        element: <Cart />,
      },
      {
        path: "/address",
        element: <Address />,
      },
      {
        path: "/pay",
        element: <Redirect />,
      },
      {
        path: "/admin",
        element: <Orders />,
      },
      {
        path: "/profile",
        element: <Profile />,
        children: [
          { index: true, element: <AboutMe /> },
          { path: "orders", element: <MyOrders /> },
          { path: "adresses", element: <MyAddresses /> },
        ],
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
    ],
  },
]);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    axios({
      method: "POST",
      url: `${BASE_URI}/api/v1/users/refresh-token`,
    }).then(
      (res) => {
        dispatch(userSliceAction.setUser(res.data.data.user));
      },
      (err) => {
        if (err) console.log(err);
      }
    );
  }, [dispatch]);

  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
