import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <>
      <section className="w-full bg-[#F3F4F6] py-8">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-4">
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <span className="font-bold text-2xl text-[#1F2937] mb-4">
              About Us
            </span>
            <span className="font-normal text-base text-[#4B5563]">
              Bringing you the finest selection of meats, sourced from local
              farms to your doorstep. Our mission is to provide quality and
              convenience.
            </span>
          </div>
          <div className="flex flex-col items-center">
            <span className="font-bold text-2xl text-center mb-4">
              Quick Links
            </span>
            <ul className="space-y-2 flex flex-col">
              <NavLink
                to={"/"}
                className="font-normal text-base text-[#4B5563]"
              >
                Home
              </NavLink>
              <NavLink
                to={"/categories"}
                className="font-normal text-base text-[#4B5563]"
              >
                Products
              </NavLink>
              <NavLink
                to={"/about"}
                className="font-normal text-base text-[#4B5563]"
              >
                About
              </NavLink>
            </ul>
          </div>
          <div className="flex flex-col items-center md:items-start text-center md:text-left mx-auto">
            <span className="font-bold text-2xl text-center mb-4">
              Contact Us
            </span>
            <span className="font-normal text-base text-[#4B5563]">
              Email:{" "}
              <a href="mailto:contact@pujkaak.com">contact@pujkaak.com</a>
            </span>
            <span className="font-normal text-base text-[#4B5563]">
              Phone: <a href="tel:9055414246">+91 9055414246</a>
            </span>
            <div className="flex gap-4 mt-4">
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="text-[#4B5563] text-2xl"
                />
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="text-[#4B5563] text-2xl"
                />
              </a>
              <a
                href="https://www.instagram.com/pujjkaak?igsh=MXJpM2k1Zm5uNm5hMw=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="text-[#4B5563] text-2xl"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <span className="font-normal text-sm text-[#4B5563]">
            © 2024 PUJ - KAAK. All rights reserved.
          </span>
        </div>
      </section>
    </>
  );
}

export default Footer;
