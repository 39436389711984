import axios from "axios";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import img2 from "../../assets/quality2.png";

import { userSliceAction } from "../../Store/userSlice";
import { BASE_URI } from "../../URI/baseUri";

axios.defaults.withCredentials = true;

function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setApiError("Please fix the form errors.");
      setShowModal(true);
      return;
    }

    try {
      axios({
        method: "POST",
        url: `${BASE_URI}/api/v1/users/register`,
        data: {
          fullName: fullname,
          email,
          mobileNo: `+91${phone}`,
          password,
        },
      });
      setShowModal(true);
      setApiError("");
    } catch (err) {
      setApiError(
        err.response && err.response.data.message
          ? err.response.data.message
          : "Something went wrong. Please try again."
      );
      setShowModal(true);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!fullname) errors.fullname = "Full Name is required";
    if (!phone || phone.length <= 3) errors.phone = "Phone Number is required";
    if (!password) errors.password = "Password is required";
    return errors;
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (!phone || !otp) {
      setApiError("Provide an OTP");
      setShowModal(true);
      return;
    }

    try {
      const res = await axios({
        method: "POST",
        url: `${BASE_URI}/api/v1/users/verify-otp`,
        data: {
          mobileNo: phone,
          otp: otp,
        },
      });
      dispatch(userSliceAction.setUser(res.data.data.user));
      setShowModal(false);
      navigate("/");
    } catch (err) {
      setApiError(
        err.response && err.response.data.message
          ? err.response.data.message
          : "Invalid OTP. Please try again."
      );
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setApiError("");
  };

  return (
    <>
      <section className="flex justify-center items-center min-h-screen py-6 bg-gray-100">
        <div className="w-full max-w-md md:max-w-lg lg:max-w-xl p-4 sm:p-8 md:p-12 lg:p-16 mt-[25px] bg-white border border-gray-200 rounded-lg shadow-lg relative">
          <div className="flex flex-col items-center mb-8">
            <img className="w-24 h-24 rounded-full" src={img2} alt="Avatar" />
            <h1 className="text-3xl md:text-4xl font-bold mt-4">
              Create Account
            </h1>
          </div>
          {apiError && (
            <p className="text-center text-red-600 text-sm mb-4">{apiError}</p>
          )}
          <form onSubmit={formSubmitHandler} className="space-y-6">
            <div className="flex flex-col">
              <label
                htmlFor="fullname"
                className="mb-2 font-medium text-gray-700"
              >
                Full Name
              </label>
              <input
                id="fullname"
                className={`h-12 border ${
                  errors.fullname ? "border-red-500" : "border-gray-300"
                } rounded px-4`}
                type="text"
                placeholder="Enter Full Name"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
              {errors.fullname && (
                <p className="text-red-500 text-sm mt-1">{errors.fullname}</p>
              )}
            </div>
            <div className="flex flex-col">
              <label htmlFor="email" className="mb-2 font-medium text-gray-700">
                Email (Optional)
              </label>
              <input
                id="email"
                className="h-12 border border-gray-300 rounded px-4"
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="phone" className="mb-2 font-medium text-gray-700">
                Phone Number
              </label>
              <div className="flex">
                <span className="flex items-center justify-center px-4 h-12 border border-gray-300 rounded-l bg-gray-100">
                  +91
                </span>
                <input
                  id="phone"
                  className={`h-12 border w-full ${
                    errors.phone ? "border-red-500" : "border-gray-300"
                  } rounded px-4`}
                  type="tel"
                  placeholder="Enter Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              {errors.phone && (
                <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
              )}
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="password"
                className="mb-2 font-medium text-gray-700"
              >
                Password
              </label>
              <input
                id="password"
                className={`h-12 border ${
                  errors.password ? "border-red-500" : "border-gray-300"
                } rounded px-4`}
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errors.password && (
                <p className="text-red-500 text-sm mt-1">{errors.password}</p>
              )}
            </div>
            <button
              type="submit"
              className="w-full h-12 bg-red-600 text-white text-lg font-medium rounded-lg"
            >
              Sign Up Now!
            </button>
            <p className="text-center text-gray-600 text-sm mt-4">
              By signing up, you agree to our Terms of Service and Privacy
              Policy
            </p>
            <p className="text-center text-sm mt-2">
              Already have an account?{" "}
              <NavLink
                className="text-blue-600 font-bold tracking-wider"
                to="/login"
              >
                Login
              </NavLink>
            </p>
          </form>
          {showModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-xl font-bold mb-4">
                  {apiError ? "Error" : "Verify OTP"}
                </h2>
                {apiError ? (
                  <>
                    <p className="text-red-600 mb-4">{apiError}</p>
                    <button
                      className="w-full h-10 bg-red-600 text-white font-medium rounded-lg"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </>
                ) : (
                  <>
                    <input
                      className="h-10 border border-gray-300 rounded px-4 mb-4 w-full"
                      type="text"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                    <button
                      className="w-full h-10 bg-red-600 text-white font-medium rounded-lg"
                      onClick={handleOtpSubmit}
                    >
                      Verify
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Signup;
