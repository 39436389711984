import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { userSliceAction } from "../../Store/userSlice";
import { useEffect, useState } from "react";
import { BASE_URI } from "../../URI/baseUri";
import { userCartActions } from "../../Store/cartSlice";

axios.defaults.withCredentials = true;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setError("");
    if (!phone || !password) {
      setError("Please enter both mobile number and password.");
      return;
    }

    axios({
      method: "POST",
      url: `${BASE_URI}/api/v1/users/login`,
      data: {
        mobileNo: `+91${phone}`,
        password,
      },
    }).then(
      async (res) => {
        axios({
          method: "GET",
          url: `${BASE_URI}/api/v1/cart/get`,
        }).then(
          (res) => {
            console.log(res.data.data);
            dispatch(userCartActions.setCart(res.data.data));
          },
          (err) => {
            console.log(err);
          }
        );

        dispatch(userSliceAction.setUser(res.data.data.user));
        navigate("/");
      },
      (err) => {
        setError(err.response.data.message);
      }
    );
  };

  return (
    <>
      <section className="flex justify-center items-center mt-[50px] mb-[50px]">
        <div className="w-full max-w-md md:max-w-lg lg:max-w-xl p-4 sm:p-8 md:p-12 lg:p-16 relative bg-white border border-gray-200 rounded-lg shadow-bl">
          <form action="" onSubmit={formSubmitHandler}>
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8">
              Sign In
            </h2>
            {error && (
              <div className="text-red-600 text-center mb-4">{error}</div>
            )}
            <div className="flex flex-col w-full mb-6">
              <label htmlFor="phone" className="mb-2 font-medium text-gray-700">
                Mobile Number
              </label>
              <div className="flex">
                <span className="flex items-center justify-center px-4 h-12 border border-gray-300 rounded-l bg-gray-100">
                  +91
                </span>
                <input
                  id="phone"
                  className="h-12 border border-gray-300 rounded-r px-4 w-full"
                  type="text"
                  placeholder="Enter Mobile Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col w-full mb-6">
              <label
                htmlFor="password"
                className="mb-2 font-medium text-gray-700"
              >
                Password
              </label>
              <input
                id="password"
                className="h-12 border border-gray-300 rounded px-4"
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex justify-between items-center text-blue-600 mb-6">
              <NavLink to="/Signup">Forget your password?</NavLink>
              <NavLink to="/Signup">Create new account!</NavLink>
            </div>
            <button
              className="w-full h-12 bg-red-600 text-white text-lg font-medium rounded-lg mb-4"
              type="submit"
            >
              Login
            </button>
            <div className="flex items-center justify-center">
              <input id="remember" type="checkbox" className="mr-2" />
              <label htmlFor="remember" className="text-gray-600">
                Remember me
              </label>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Login;
