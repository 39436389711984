import React from "react";
import test1 from "../../assets/test.jpg";
import test2 from "../../assets/test2.jpg";
import test3 from "../../assets/test3.jpg";

const reviews = [
  {
    name: "Basit Bashir",
    text: "I have been using PUJJ KAAK for my fresh meat deliveries for the past 2 months, and I couldn't be happier. The meat is always fresh, and the quality is consistently top-notch. Their delivery is always on time.",
    image: test1,
  },
  {
    name: "Zahid Hussain",
    text: "PUJJ KAAK has changed the way I shop for meat. The convenience of having fresh, high-quality meat delivered to my home is unbeatable. The meat is flavorful and tender, and you can tell it is truly fresh. The customer service is excellent, and they always make sure I am satisfied with my order.",
    image: test2,
  },
  {
    name: "Fazil Shafi",
    text: "As a busy professional, I appreciate the convenience and quality that PUJJ KAAK offers. Their fresh meat delivery service is a game-changer. The selection is fantastic, and the meat is always fresh and delicious. The best part is, I don't have to worry about the hassle of going to the store.",
    image: test3,
  },
];

function Reviews() {
  return (
    <section className="w-full py-12 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-8">
          Customer Reviews
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 place-items-center">
          {reviews.map((review, index) => (
            <div
              key={index}
              className="rounded-lg p-6 flex flex-col items-center justify-center text-center border-2 transition-all duration-300 hover:shadow-lg hover:scale-105 h-[350px] w-[300px] hover:bg-gray-100"
            >
              <img
                src={review.image}
                alt={`testimonial${index + 1}`}
                className="w-24 h-24 rounded-full mb-4 shadow-lg"
              />
              <span className="text-sm font-semibold text-gray-700 mb-1">
                {review.name}
              </span>
              <div className="text-base text-gray-800 overflow-y-auto leading-[26.5px] h-32 px-2 hide-scrollbar">
                {review.text}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Reviews;
