import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

function Hero() {
  return (
    <section className="container mx-auto mb-20 flex flex-col md:flex-row gap-8 relative md:top-[20px]">
      <div className="flex flex-col w-full md:w-1/2 p-4 md:p-8 gap-8 md:gap-8 text-center md:text-left items-center md:items-start">
        <span className="text-6xl lg:text-8xl font-extrabold leading-tight md:leading-snug lg:leading-tight">
          Fresh Meat <br />
          Delivered
        </span>
        <span className="text-xl md:text-2xl lg:text-3xl font-extrabold leading-snug md:leading-snug lg:leading-tight text-gray-500">
          Premium Quality and Convenience
        </span>
        <NavLink
          to="/categories"
          className="w-48 md:w-64 lg:w-80 h-12 md:h-14 lg:h-16 px-4 md:px-8 py-2 md:py-3 rounded-lg bg-red-600 text-lg md:text-xl lg:text-2xl font-semibold text-white transform transition-transform duration-300 hover:scale-105 flex items-center justify-center gap-4"
        >
          Order Now <FontAwesomeIcon icon={faArrowRight} className="" />
        </NavLink>
      </div>
      <div className="w-96 mx-auto md:w-[37%] h-64 md:h-auto flex justify-center md:justify-end md:mt-0">
        <div className="bg-profile-bg bg-cover bg-center w-full md:w-[550px] h-full md:h-[470px] rounded-xl shadow-bl animate-wiggle"></div>
      </div>
    </section>
  );
}

export default Hero;
