import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img1 from "../../assets/step1.png";
import img2 from "../../assets/step2.png";
import img3 from "../../assets/step3.png";
import {
  faLeaf,
  faList,
  faShippingFast,
} from "@fortawesome/free-solid-svg-icons";

function QualitySection() {
  return (
    <section className="py-16 bg-gray-100">
      <div className="text-center mb-12">
        <h2 className="text-4xl md:text-5xl font-extrabold text-[#1C1917] mb-4">
          Premium Quality
        </h2>
        <p className="text-lg md:text-xl text-[#78716c]">Only the Best Cuts</p>
      </div>
      <div className="flex flex-col md:flex-row gap-12 justify-center items-center">
        <div className="flex flex-col items-center gap-4">
          <img
            src={img3}
            alt="Step 1"
            className="w-80 h-56 md:w-96 md:h-64 rounded-2xl object-cover shadow-bl transform transition-transform duration-300 hover:scale-105"
          />

          <h4 className="text-xl font-semibold text-[#1C1917]">
            Convenient Delivery
          </h4>
          <p className="text-base text-[#78716C]">
            Fast and Reliable to your Door{" "}
            <FontAwesomeIcon icon={faShippingFast} className="ml-2" />
          </p>
        </div>
        <div className="flex flex-col items-center gap-4">
          <img
            src={img2}
            alt="Step 2"
            className="w-80 h-56 md:w-96 md:h-64 rounded-2xl object-cover shadow-bl transform transition-transform duration-300 hover:scale-105"
          />

          <h4 className="text-xl font-semibold text-[#1C1917]">
            Wide Selection
          </h4>
          <p className="text-base text-[#78716C]">
            From Beef to special Meat{" "}
            <FontAwesomeIcon className="ml-2" icon={faList} />
          </p>
        </div>
        <div className="flex flex-col items-center gap-4">
          <img
            src={img1}
            alt="Step 3"
            className="w-80 h-56 md:w-96 md:h-64 rounded-2xl object-cover shadow-bl transform transition-transform duration-300 hover:scale-105"
          />

          <h4 className="text-xl font-semibold text-[#1C1917]">
            Sustainable Sourcing
          </h4>
          <p className="text-base text-[#78716C]">
            Ethically sourced always fresh
            <FontAwesomeIcon className="ml-2" icon={faLeaf} />
          </p>
        </div>
      </div>
    </section>
  );
}

export default QualitySection;
