import React from "react";

function Orders() {
  const orders = [
    { id: "001", productName: "Product A", address: "123 Main St" },
    { id: "002", productName: "Product B", address: "456 Elm St" },
    { id: "003", productName: "Product C", address: "789 Oak St" },
    { id: "004", productName: "Product D", address: "101 Maple St" },
  ];

  const deliveryPartners = ["Partner 1", "Partner 2", "Partner 3", "Partner 4"];

  return (
    <div className="container mx-auto p-4">
      {orders.map((order) => (
        <div key={order.id} className="mt-4">
          <div className="flex justify-between items-center border border-gray-400 rounded-md p-2">
            <div className="flex items-center">
              <span className="text-gray-600 font-medium mr-2">{order.id}</span>
              <span className="text-gray-600 font-medium mr-2">
                {order.productName}
              </span>
              <span className="text-gray-600 font-medium">{order.address}</span>
            </div>
            <div className="flex items-center">
              <select className=" text-gray-800 font-medium border-[1px] border-black py-1 px-2 rounded-md mr-2">
                <option value="" disabled selected>
                  Select Partner
                </option>
                {deliveryPartners.map((partner, index) => (
                  <option key={index} value={partner}>
                    {partner}
                  </option>
                ))}
              </select>
              <button className="bg-red-600 text-white font-medium py-1 px-2 rounded-md tracking-wider">
                Assign
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Orders;
