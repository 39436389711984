import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img1 from "../../assets/step1.png";
import img2 from "../../assets/step2.png";
import img3 from "../../assets/step3.png";
import {
  faHome,
  faShoppingCart,
  faThList,
} from "@fortawesome/free-solid-svg-icons";

function Works() {
  return (
    <section className="py-16 bg-white">
      <div className="text-center mb-12">
        <h2 className="text-4xl md:text-5xl font-extrabold text-[#1C1917] mb-4">
          How It Works
        </h2>
        <p className="text-lg md:text-xl text-[#78716c]">
          Easy Steps To Get Your Personalized Meat
        </p>
      </div>
      <div className="flex flex-col md:flex-row gap-12 justify-center items-center">
        <div className="flex flex-col items-center gap-4">
          <img
            src={img1}
            alt="Step 1"
            className="w-80 h-56 md:w-96 md:h-64 rounded-2xl object-cover shadow-bl transform transition-transform duration-300 hover:scale-105"
          />
          <h3 className="text-lg font-[400] text-[#EF4444]">Step 1</h3>
          <h4 className="text-xl font-semibold text-[#1C1917]">
            Browse Products
          </h4>
          <p className="text-base text-[#78716C]">
            Explore our wide range of Meats{" "}
            <FontAwesomeIcon className="ml-2" icon={faThList} />
          </p>
        </div>
        <div className="flex flex-col items-center gap-4">
          <img
            src={img2}
            alt="Step 2"
            className="w-80 h-56 md:w-96 md:h-64 rounded-2xl object-cover shadow-bl transform transition-transform duration-300 hover:scale-105"
          />
          <h3 className="text-lg font-[400] text-[#EF4444]">Step 2</h3>
          <h4 className="text-xl font-semibold text-[#1C1917]">Place Order</h4>
          <p className="text-base text-[#78716C]">
            Add to Cart and Checkout{" "}
            <FontAwesomeIcon className="ml-2" icon={faShoppingCart} />
          </p>
        </div>
        <div className="flex flex-col items-center gap-4">
          <img
            src={img3}
            alt="Step 3"
            className="w-80 h-56 md:w-96 md:h-64 rounded-2xl object-cover shadow-bl transform transition-transform duration-300 hover:scale-105"
          />
          <h3 className="text-lg font-[400] text-[#EF4444]">Step 3</h3>
          <h4 className="text-xl font-semibold text-[#1C1917]">
            Receive Delivery
          </h4>
          <p className="text-base text-[#78716C]">
            Enjoy Fresh Meat at Your Home
            <FontAwesomeIcon className="ml-2" icon={faHome} />
          </p>
        </div>
      </div>
    </section>
  );
}

export default Works;
